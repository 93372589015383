<template>
  <main-body-section class="tension-page">
    <template #map>
      <div id="map" class="h-100 z-1"></div>
    </template>
    <base-panel title="انتخاب زمین" :rightPanel="false" class="text-center">
      <template #default>
        <vue-element-loading
          :active="farmsNameListLoadingState"
          text=""
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <farms-name-list
          :farmsNameList="farmsSource"
          :farmersNameList="farmsSource"
          :selectFarmLoading="selectFarmLoading"
          @selectFarm="selectFarm"
        ></farms-name-list>
      </template>
    </base-panel>
    <div class="tension-detail" v-if="selectedFarm">
      <no-data
        :show="farmAllTensionList.length === 0"
        message="تنش برای زمین ثبت نشده است"
        class="emptyTitle"
      />
      <div v-if="farmAllTensionList.length > 0">
        <div
          v-for="(item, index) of farmAllTensionList"
          :key="index"
          :class="
            item.id == tensionDetail.id
              ? 'tension-list-info active'
              : 'tension-list-info'
          "
          @click="GetTensionDetail(item)"
        >
          <img :src="item.image" alt="" @click="showModal()" />
          <div class="tension-detail-text">
            <ul>
              <li>{{ item.type }}</li>
              <li>{{ item.createDateTime }}</li>
            </ul>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <base-panel :rightPanel="true" v-if="selectedFarm" title="مشخصات زمین">
        <template #default>
          <farm-info
            :farmInfo="selectedFarm"
            :area="selectedFarmArea"
          ></farm-info>
        </template>
      </base-panel>
    </transition>
    <VueModal
      :title="tensionDetail.type"
      v-model="tensionDetailModal"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      :bg-in-class="`animate__fadeInDown`"
      :bg-out-class="`animate__fadeOutDown`"
    >
      <img :src="tensionDetail.image" class="tensionDetailImage" />
    </VueModal>
  </main-body-section>
</template>

<script>
import { mapActions } from "vuex";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ZoomControl } from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import { calculateBboxService } from "../../services/calculateBbox.service";
import polyGonStructure from "../../store/data/polygonFeature.json";
import { formatPolygonService } from "../../services/formatPolygon.service";

import "vue-select/dist/vue-select.css";
import * as moment from "jalali-moment";
import { apiUrlRoot } from "../../constants/config";
import MainBodySection from "../../components/mainBodySection.vue";
import FarmsNameList from "../../components/map/FarmsNameList.vue";
import BasePanel from "../../components/map/BasePanel.vue";
import FarmInfo from "../../components/map/FarmInfo.vue";
import NoData from "../../components/no-data.vue";

export default {
  name: "TensionForm",
  components: {
    FarmInfo,
    BasePanel,
    FarmsNameList,
    MainBodySection,
    NoData,
  },
  data() {
    return {
      map: null,
      marker: null,
      loading: false,
      countryData: [],
      tensionDetailModal: false,
      farmsNameListLoadingState: false,
      selectFarmLoading: false,
      deleteConfirm: false,
      deleteMessage: "خطا در دریافت اطلاعات سطر",
      deleteId: 0,
      farmView: "",
      selectedFarmArea: null,
      farmSource: [],
      polygonCenter: null,
      formatedPolygon: null,
      farmSelected: "",
      farmsSource: [],
      cultiavtionType: ["دیم", "آبی", "دیم -آبیاری تکمیلی"],
      selectedFarm: "",
      farmAllTensionList: [],
      tensionDetail: {
        id: 0,
        lng: "",
        lat: "",
        image: "",
        type: "",
        description: "",
        createDateTime: "",
      },
    };
  },
  watch: {
    farmSelected: function () {
      this.selectFarm(this.farmSelected);
    },
  },
  methods: {
    ...mapActions("farmTension", ["GetAllByFarmId"]),
    ...mapActions("farm", ["GetsTextValue", "Get"]),

    addPolygonLayer(polygon) {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      };
      // Add a data source containing GeoJSON data.
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
          // this.map.removeLayer("outline");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
    },
    async loadFarmMap(cordinates, farmId) {
      polyGonStructure.features[0].geometry.coordinates = [cordinates];
      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.selectedFarmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      var polygon = turf.polygon([cordinates]);
      let center = turf.centerOfMass(polygon);
      this.polygonCenter = center["geometry"]["coordinates"];
      this.formatedPolygon = formatPolygonService.polygonreFormat(cordinates);
      let box = calculateBboxService.calculateBox(cordinates);
      this.addPolygonLayer(cordinates);
      this.map.fitBounds(box, { padding: 100 });
      this.map.on("load", () => {});
      await this.GetAllTension(farmId);
    },
    async selectFarm(item) {
      this.selectFarmLoading = true;
      var result = await this.Get({ id: item.value });
      this.kashtDatePersian = moment(result.cultivationsDate)
        .locale("fa")
        .format("YYYY-MM-D");
      result.kashtDatePersian = this.kashtDatePersian;
      this.selectedFarm = result;
      this.loadFarmMap(this.selectedFarm.cordinates, item.value);
      this.selectFarmLoading = false;
    },
    async GetAllFarms() {
      this.farmsNameListLoadingState = true;
      this.farmsSource = await this.GetsTextValue({
        farmerType: null,
        farmerId: null,
      });
      this.farmsNameListLoadingState = false;
    },
    async GetAllTension(farmId) {
      var result = await this.GetAllByFarmId({ farmId: farmId });
      if (result) {
        result.forEach((element) => {
          let marker = new mapboxgl.Marker()
            .setPopup(
              new mapboxgl.Popup().setHTML(`<h5>${element.description}</h5>`)
            )
            .setLngLat([element.lat, element.lng])
            .addTo(this.map);
          this.farmAllTensionList.push({
            id: element.id,
            lng: element.lng,
            lat: element.lat,
            image: `${apiUrlRoot}/TensionImage/${element.image}`,
            type: element.type,
            description: element.description,
            createDateTime: element.createDateTime,
            marker: marker,
          });
        });
        if (this.farmAllTensionList.length > 0)
          this.GetTensionDetail(this.farmAllTensionList[0]);
      } else {
        this.$notify({
          title: "خطا رخ داده است",
          text: "برای زمین تنشی یافت نشد <br /><br />",
          type: "info",
        });
      }
    },
    GetTensionDetail(item) {
      this.tensionDetail.id = item.id;
      this.tensionDetail.lng = item.lng;
      this.tensionDetail.lat = item.lat;
      this.tensionDetail.image = item.image;
      this.tensionDetail.type = item.type;
      this.tensionDetail.description = item.description;
      this.tensionDetail.createDateTime = item.createDateTime;
      this.setMarkerColor(item.marker);
    },
    async showModal() {
      this.tensionDetailModal = true;
    },
    setMarkerColor(marker) {
      this.map._markers.forEach((element) => {
        let makElement = element
          .getElement()
          .querySelectorAll('svg [fill="red"]');
        if (makElement.length > 0) {
          makElement[0].setAttribute("fill", "#3FB1CE");
        }
      });

      let markerElement = marker.getElement();
      markerElement
        .querySelectorAll('svg [fill="' + marker._color + '"]')[0]
        .setAttribute("fill", "red");
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";

    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      center: [53.12609431323281, 33.59332186046887], // starting position [lng, lat]
      zoom: 5,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.map.addControl(new ZoomControl(), "bottom-left");
    this.map.on("load", () => {});
    this.map.on("draw.create", this.addPolygonLayer);
    this.GetAllFarms();
    this.$confirm({
      message: `برای نمایش تنش یک زمین را انتخاب کنید`,
      button: {
        yes: "باشه",
      },
    });
  },
  created() {},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.tension-page .ads-wrapper img {
  width: 22rem !important;
}
.no-result-image {
  max-width: 14rem !important;
  width: 30vw !important;
}
</style>
<style scoped>
.tensionDetailImage {
  width: 100%;
  height: calc(100vh - 50vh);
}

.tensionDetailDesc {
  color: #37474f;
  padding: 10px;
}

.tension-detail {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  height: 35vh;
  overflow-y: auto;
  padding: 1rem;
  width: 25vw;
  border-radius: 14px;
}

.tension-list-info.active {
  border: 3px solid greenyellow;
}

.tension-list-info {
  display: flex;
  width: 100%;
  border: 1px solid #bbb;
  border-radius: 14px;
  margin: 9px 0px;
  padding: 0px;
  cursor: pointer;
}
.tension-list-info img {
  width: 4vw;
  border-radius: 0px 14px 14px 0px;
}
.tension-detail-text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.tension-detail-text ul {
  display: flex;
  justify-content: space-between;
  padding: 0px 11px;
  list-style: none;
  border-bottom: 1px dashed #bbb;
  margin: 4px 11px;
}
.tension-detail-text ul li {
  font-size: 15px;
  color: #37474f;
  font-family: IRANSansWeb;
}
.tension-detail-text p {
  font-size: 12px;
  font-family: yekan;
  font-weight: bold;
  padding: 3px;
}
</style>
